<!-- =========================================================================================
    File Name: Error404.vue
    Description: 404 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="page">
        <nav role="navigation">
            <div class="nav-background">
                <div class="img-background"></div>
            </div>
            <div class="nav-wrapper container">
                <a href="/" class="brand-logo">
                    <img src="@/assets/images/logo/cactus-big-logo1_white.png" alt="Cactus Logo" />
                </a>
                <a href="#" data-target="dropdown-lang" class="dropdown-trigger btn transparent white-text z-depth-0">
                    <i18n />
                </a>

                <div class="nav-header nav-header-gateway center">
                    <h1>CactusCard</h1>
                    <h3 class="light flow-text cactuscard-subtitle" v-html="$t('landing.subtitle')"></h3>
                </div>
            </div>
        </nav>

        <div id="content">
            <div id="contact" class="section">
                <div class="container">
                    <div class="row row-flex">
                        <div class="col s12">
                            <div class="card-panel z-depth-3">
                                <div class="row row-flex">
                                    <div class="col s12 m6 l8 col-form">
                                        <h4 class="deep-purple-text text-darken-3">Send us a message</h4>
                                        <form id="form-contact" accept-charset="utf-8" >
                                            <div class="input-field">
                                                <input type="text" v-model="form.name" id="name" name="name" v-validate="'required|alpha_spaces'" placeholder="Enter your name" />
                                                <span class="text-danger">{{ errors.first('name') }}</span>
                                            </div>
                                            <div class="input-field">
                                                <input type="email" v-model="form.email" v-validate="'required|email'" id="email" name="email" placeholder="Enter your email" />
                                                <span class="text-danger">{{ errors.first('email') }}</span>
                                            </div>
                                            <div class="input-field">
                                                <input type="text" v-model="form.subject" id="subject" name="subject" v-validate="'required'" placeholder="Enter a subject" />
                                                <span class="text-danger">{{ errors.first('subject') }}</span>
                                            </div>
                                            <div class="input-field">
                                                <textarea class="materialize-textarea" v-model="form.message" id="message" v-validate="'required'"  name="message" rows="4" placeholder="Enter your message"></textarea>
                                                <span class="text-danger">{{ errors.first('message') }}</span>
                                            </div>
                                            <div class="apply-now center">
                                                <a @click="sendContact" class="btn btn-large btn-rounded btn-flat waves-effect green accent-4"> SEND</a>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col s12 m6 l4 col-info">
                                        <h4>Contact information</h4>
                                        <p>
                                            Guareschi y Paz SA de CV <br>
                                            Meseta 10, Apart 2LT, Benito Juarez<br>
                                            CP 77505, Quintana Roo, Mexico
                                        </p>
                                        <div class="separador"></div>
                                        <p>
                                           +52 9982531048
                                        </p>
                                        <div class="separador"></div>
                                        <p>
                                            info@cactusprepaid.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <footer class="page-footer text-center">
            <div class="container">
                <div class="row">
                    <div class="col l4 s12">
                        <a href="" class="brand-logo">
                            <img src="@/assets/images/logo/cactus-big-logo1_white.png" alt="Cactus Logo" />
                        </a>
                    </div>
                    <div class="col l8 s12">
                        <div class="row">
                            <div class="col s12 m6 text-left">
                                <h6 class="heading f-16 purple-text text-darken-3">{{$t('landing.company')}}</h6>
                                <ul>
                                    <li><a class="text-white" href="/terms">{{$t('landing.terms_label')}}</a></li>
                                    <li><a class="text-white" href="/privacy">{{$t('landing.privacy_label')}}</a></li>
                                    <li><a class="text-white" href="/mpolicy">{{$t('landing.policy_label')}}</a></li>
                                </ul>
                            </div>
                            <!--                            <div class="col s12 m3">-->
                            <!--                                <h6 class="heading f-16 purple-text text-darken-3">Products</h6>-->
                            <!--                                <ul>-->
                            <!--                                    <li><a href="<?php echo url_for('home/gateway') ?>">Gateway</a></li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <div class="col s12 m3 text-left">
                                <h6 class="heading f-16 purple-text text-darken-3">{{$t('landing.help')}}</h6>
                                <ul>
                                    <li><a class="text-white" href="/contact">{{$t('landing.contact')}}</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer-copyright transparent">
                <div class="container text-darken-1">
                    Copyright © 2020 Cactus Prepaid. All rights reserved.
                    <div style="margin-top:0.1rem"></div>
                    <small>
                        Guareschi y Paz SA de CV
                        Meseta 10, Apart 2LT, Benito Juarez, CP 77505, Quintana Roo, Mexico

                    </small>
                </div>
            </div>
        </footer>

    </div>
</template>
<script>

    import { mapActions} from 'vuex'
    import I18n   from '../../layouts/components/navbar/I18n.vue'
    import { Validator } from 'vee-validate';
    const dict = {
        custom: {
            name: {
                required: 'Name is required',
                alpha: "Name may only contain alphabetic characters"
            },
            subject: {
                required: 'Subject is required'
            },
            message: {
                required: 'Message is required'
            },
            email: {
                required: 'Email is required',
                email: "Please enter valid email"
            }
        }
    };
    // register custom messages
    Validator.localize('en', dict);
    export default {
        components: {
            I18n
        },
        data () {
            return {
              form:{
                  name: '',
                  email: '',
                  subject: '',
                  message: ''
              }
            }
        },
        methods:{
            ...mapActions('user', ['contact']),
            sendContact(){
                    return new Promise((resolve, reject) => {
                        this.$validator.validateAll().then(result => {
                            if (result) {
                                this.contact(this.form)
                                resolve(true)
                            } else {
                                reject("correct all values");
                            }
                        })
                    })

            },
            goTo(){
                window.open('http://localhost:8080/Fees.pdf', '_blank');
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../assets/cactus/preloader.css";
    @import "../../assets/cactus/animate.css";
    @import "../../assets/cactus/materialize.min.css";
    @import "../../assets/cactus/p.css";
</style>
